import { FooterService } from '@src/app/components/footer/footer.service';
import { LoginService } from '@src/app/routes/login/login.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadingService } from '@app/services/loading.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { isPlatform, Platform } from '@ionic/angular';
import { CapacitorUpdater } from 'capacitor-updater';
import { SplashScreen } from '@capacitor/splash-screen';
import { environment } from '@src/environments/environment';
import axios from 'axios';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import mautic from 'mautic-tracking';
import { CurrentUserDto } from './routes/login/login.dto';
import { GoogleTagManagerService } from './services/google-tag-manager.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'app-cashless';

  finishedLoadingAndUpdating = false;

  showMenu = true;

  subscriptionLoading: Subscription;

  subscriptionLogin: Subscription;

  currentUser: CurrentUserDto;

  constructor(
    private platform: Platform,
    private loadingService: LoadingService,
    private spinner: NgxSpinnerService,
    private googleTagManagerService: GoogleTagManagerService,
    private router: Router,
    private footerService: FooterService,
    private loginService: LoginService
  ) {
    mautic.initialize('https://mautic.cashless-app.com.br/mtc.js');
  }

  ngOnInit() {
    this.subscriptionLoading = this.loadingService.loading.subscribe((value) => {
      if (value) {
        this.spinner.show();
      } else {
        this.spinner.hide();
      }
    });

    this.update();

    this.subscriptionLogin = this.loginService.currentUser.subscribe((user) => {
      this.currentUser = user;
    });

    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        const pageView = {
          event: 'pageView',
          pagePath: item.url,
          pageTitle: 'App Cashless',
          visitorType: 'customer',
        };

        this.googleTagManagerService.pushTag(pageView);

        if (!this.currentUser) {
          mautic.pageView();
        } else {
          const name = this.currentUser.name.split(' ');
          mautic.pageView({
            email: this.currentUser.email,
            firstname: name[0],
            lastname: name[name.length - 1],
            mobile: this.currentUser.phone,
            company: 'Cashless',
          });
        }

        this.footerService.checkIsCashier();
      }
    });
  }

  ngOnDestroy() {
    this.subscriptionLoading.unsubscribe();
    this.subscriptionLogin.unsubscribe();
  }

  async update() {
    this.platform.ready().then(async () => {
      await SplashScreen.hide();

      if (!environment.production || !isPlatform('mobile')) {
        this.finishedLoadingAndUpdating = true;
        return;
      }

      do {
        try {
          const remoteVersion = await axios.get<{ version: string }>(
            'https://app.cashless-app.com.br/dist/version.json'
          );

          if (remoteVersion?.data?.version === environment.version) {
            this.finishedLoadingAndUpdating = true;
            return;
          }

          const updater = await CapacitorUpdater.download({
            url: 'https://app.cashless-app.com.br/dist/dist.zip',
          });

          if (!!updater?.version) {
            await CapacitorUpdater.set({
              version: updater.version,
              versionName: remoteVersion.data.version,
            });
          }
        } catch (e) {
          console.log(e);
        }
      } while (!this.finishedLoadingAndUpdating);
    });
  }
}
