import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class LoadingService {
  readonly loading: Observable<boolean>;

  private loadingSubject = new BehaviorSubject<boolean>(false);

  private totalRequests: { name: string; total: number }[] = [];

  constructor() {
    this.loading = this.loadingSubject.asObservable();
  }

  increment(name: string) {
    const index = this.totalRequests.findIndex((x) => x.name === name);

    if (index < 0) {
      this.totalRequests.push({
        name,
        total: 1,
      });
    } else {
      this.totalRequests[index].total++;
    }

    this.checkLoading();
  }

  decrement(name: string) {
    const index = this.totalRequests.findIndex((x) => x.name === name);

    if (index < 0) {
      this.totalRequests.push({
        name,
        total: 0,
      });
    } else {
      this.totalRequests[index].total--;
    }

    this.checkLoading();
  }

  private checkLoading() {
    const total = this.totalRequests.reduce((previousValue, currentValue) => previousValue + currentValue.total, 0);

    this.loadingSubject.next(total !== 0);
  }
}
