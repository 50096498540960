import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import CashlessAxios from '@app/hooks/cashless.axios';
import { CreditCardDto, TransactionDto, TransactionWithProductsDto } from '@app/routes/wallet/wallet.dto';

@Injectable({
  providedIn: 'root',
})
export class WalletService implements OnDestroy {
  readonly transactions: Observable<TransactionDto[]>;

  readonly creditCards: Observable<CreditCardDto[]>;

  readonly currentBalance: Observable<number>;

  private transactionsSubject = new BehaviorSubject<TransactionDto[]>([]);

  private creditCardsSubject = new BehaviorSubject<CreditCardDto[]>([]);

  private currentBalanceSubject = new BehaviorSubject<number>(0);

  private subscriptionCreditCards: Subscription;

  constructor(private router: Router, private cashlessAxios: CashlessAxios) {
    // Observables
    this.transactions = this.transactionsSubject.asObservable();
    this.creditCards = this.creditCardsSubject.asObservable();
    this.currentBalance = this.currentBalanceSubject.asObservable();

    // Credit Cards
    let cards: CreditCardDto[] = [];
    try {
      cards = JSON.parse(localStorage.getItem('wallet-credit-cards')) || [];
    } catch {
      cards = [];
    }
    this.creditCardsSubject.next(cards);
    this.subscriptionCreditCards = this.creditCardsSubject.subscribe((value) => {
      localStorage.setItem('wallet-credit-cards', JSON.stringify(value));
    });
  }

  ngOnDestroy() {
    this.subscriptionCreditCards.unsubscribe();
  }

  creditCardAdd(card: CreditCardDto) {
    const cards = this.creditCardsSubject.value;
    if (cards.findIndex((c) => c.nickname === card.nickname) === -1) {
      cards.push(card);
      this.creditCardsSubject.next(cards);

      return true;
    }

    return false;
  }

  creditCardRemove(card: CreditCardDto) {
    const cards = this.creditCardsSubject.value;
    const index = cards.indexOf(card);
    cards.splice(index, 1);
    this.creditCardsSubject.next(cards);
  }

  creditCardTokenize(hash) {
    return this.cashlessAxios.axios.post<{
      creditCardId: string;
      expirationMonth: string;
      expirationYear: string;
      last4CardNumber: string;
    }>(`payment/tokenization/${hash}`, null);
  }

  async balanceUpdate() {
    try {
      const response = await this.cashlessAxios.axios.get<{ balance: number }>('user/balance');
      this.currentBalanceSubject.next(response.data.balance);
    } catch (e) {}
  }

  async transactionsUpdate() {
    try {
      const response = await this.cashlessAxios.axios.get<TransactionDto[]>('/transaction/complete');
      this.transactionsSubject.next(response.data);
    } catch (e) {}
  }

  walletUpdate() {
    this.balanceUpdate();
    this.transactionsUpdate();
  }

  async clearData() {
    this.currentBalanceSubject.next(0);
    this.transactionsSubject.next([]);
    this.creditCardsSubject.next([]);
  }

  getToUser(phone: any) {
    return this.cashlessAxios.axios.get(`user/phone/${phone}`);
  }

  postTransfer(transferData: TransactionWithProductsDto) {
    return this.cashlessAxios.axios.post(`transaction`, transferData);
  }

  postTransferWithProducts(transferData: TransactionWithProductsDto) {
    return this.cashlessAxios.axios.post(`transaction/products`, transferData);
  }
}
