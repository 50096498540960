import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoginService } from '@app/routes/login/login.service';
import { CurrentUserDto } from '@app/routes/login/login.dto';
import { Subscription } from 'rxjs';
import { AlertComponent } from '@src/app/components/alert/alert.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
})
export class MyProfileComponent implements OnInit, OnDestroy {
  currentUser: CurrentUserDto;

  subscriptionCurrentUser: Subscription;

  constructor(private loginService: LoginService, private dialog: MatDialog) {}

  ngOnInit() {
    this.subscriptionCurrentUser = this.loginService.currentUser.subscribe((value) => (this.currentUser = value));
  }

  ngOnDestroy() {
    this.subscriptionCurrentUser.unsubscribe();
  }

  logout(): void {
    this.dialog.open(AlertComponent, {
      panelClass: ['dialog-primary', 'dialog-page-mobile'],
      width: '95%',
      maxWidth: 328,
      height: 'auto',
      maxHeight: '90vh',
      autoFocus: false,
      disableClose: false,
      data: {
        type: 'confirm',
        callback: () => this.loginService.logout(),
        message: `Tem certeza de que deseja sair?`,
      },
    });
  }
}
