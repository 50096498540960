import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TransactionsConfirmComponent } from '@app/routes/seller/transaction-confirm/confirm.component';
import { SellerService } from '@app/routes/seller/seller.service';
import { TransactionSellerDto, TransactionSellerTypeEnum } from '@app/routes/seller/seller.dto';
import { Subscription } from 'rxjs';
import { LoadingService } from '@src/app/services/loading.service';
import { EventsService } from '../../events/events.service';
import { EventDto } from '../../events/events.dto';
import { Moment } from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
})
export class TransactionsComponent implements OnInit, OnDestroy {
  sellerTransactions: TransactionSellerDto[] = [];

  subscriptionSellerTransactions: Subscription;

  subscriptionLoading: Subscription;

  subscriptionLastUpdate: Subscription;

  loading = true;

  subscriptionEvent: Subscription;

  currentEvent: EventDto | false;

  lastUpdate: Moment;

  intervalTransactions: any;

  transactionSellerTypeEnum = TransactionSellerTypeEnum;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private sellerService: SellerService,
    private loadingService: LoadingService,
    private eventService: EventsService
  ) {}

  ngOnInit() {
    this.subscriptionLoading = this.loadingService.loading.subscribe((value) => {
      this.loading = value;
    });

    this.subscriptionSellerTransactions = this.sellerService.sellerTransactions.subscribe((value) => {
      this.sellerTransactions = value;
    });

    this.subscriptionLastUpdate = this.sellerService.lastUpdate.subscribe((value) => {
      this.lastUpdate = value;
    });

    this.subscriptionEvent = this.eventService.currentEvent.subscribe((val) => {
      this.currentEvent = val;
    });

    this.intervalTransactions = setInterval(() => this.loadTransactions(), 1000 * 30);
  }

  loadTransactions() {
    this.sellerService.loadTransactions();
  }

  ngOnDestroy() {
    this.subscriptionLoading.unsubscribe();
    this.subscriptionSellerTransactions.unsubscribe();
    this.subscriptionEvent.unsubscribe();
    this.subscriptionLastUpdate.unsubscribe();
    clearInterval(this.intervalTransactions);
  }

  navigateToCharge(transaction: TransactionSellerDto) {
    this.router.navigateByUrl(`/seller/pix/${transaction.id}`);
  }

  confirmDialog(transaction: TransactionSellerDto): void {
    this.dialog.open(TransactionsConfirmComponent, {
      data: transaction,
      panelClass: ['dialog-primary', 'dialog-page-mobile'],
      width: '95%',
      maxWidth: 328,
      height: 'auto',
      maxHeight: '90vh',
      autoFocus: false,
      disableClose: false,
    });
  }
}
