import { LoginService } from '@app/routes/login/login.service';
import { EventsService } from './../events/events.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import CashlessAxios from '@src/app/hooks/cashless.axios';
import { TransactionDto } from '../wallet/wallet.dto';
import { EventDto } from '../events/events.dto';
import { CurrentUserDto } from '../login/login.dto';

@Injectable({
  providedIn: 'root',
})
export class CashierService implements OnDestroy {
  subscriptionEvent: Subscription;

  currentEvent: EventDto;

  subscriptionUser: Subscription;

  currentUser: CurrentUserDto | null = null;

  isCashier = new BehaviorSubject<boolean>(false);

  constructor(
    private cashlessAxios: CashlessAxios,
    private eventService: EventsService,
    private loginService: LoginService
  ) {
    this.subscriptionEvent = this.eventService.currentEvent.subscribe((value) => {
      this.currentEvent = value;
    });
    this.subscriptionUser = this.loginService.currentUser.subscribe((value) => {
      this.currentUser = value;
    });
  }

  ngOnDestroy() {
    this.subscriptionEvent.unsubscribe();
    this.subscriptionUser.unsubscribe();
  }

  async sendMoney(data: { toUserId: string; amount: number; eventId: string }) {
    return this.cashlessAxios.axios.post(`transaction/cashier`, data);
  }

  async getTransactions() {
    return (
      (await this.cashlessAxios.axios.get<TransactionDto[]>(`/transaction/cashier/${this.currentEvent.id}`)).data || []
    );
  }

  async generateCodeCashier(phone: string) {
    return this.cashlessAxios.axios.post('login/request-code-cashier', {
      phoneNumber: phone,
      eventId: this.currentEvent.id,
    });
  }

  async getLastCode(phone: string) {
    return this.cashlessAxios.axios.post('login/retrieve-code', { phoneNumber: phone, eventId: this.currentEvent.id });
  }
}
