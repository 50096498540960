import { Injectable } from '@angular/core';
import CashlessAxios from '@app/hooks/cashless.axios';
import { TransactionSellerDto } from '@app/routes/seller/seller.dto';
import { BehaviorSubject } from 'rxjs';
import moment, { Moment } from 'moment';

@Injectable()
export class SellerService {
  sellerTransactions = new BehaviorSubject<TransactionSellerDto[]>([]);

  lastUpdate = new BehaviorSubject<Moment>(null);

  abortController = new AbortController();

  constructor(private cashlessAxios: CashlessAxios) {
    this.loadTransactions();
  }

  async postTransactionUserMetadata(transactionId: string, checked: boolean) {
    try {
      await this.cashlessAxios.axios.post(`transaction/metadata`, { transactionId, checked });
      this.loadTransactions();
      return await Promise.resolve();
    } catch (e) {
      return await Promise.reject();
    }
  }

  async loadTransactions() {
    this.abortController.abort();
    this.abortController = new AbortController();

    await this.cashlessAxios.axios
      .get<TransactionSellerDto[]>(`/transaction/seller`, {
        signal: this.abortController.signal,
      })
      .then((value) => {
        this.sellerTransactions.next(value.data || []);
      })
      .catch(() => {
        this.sellerTransactions.next([]);
      });

    this.lastUpdate.next(moment());
  }

  loadCharge(id: string) {
    return this.cashlessAxios.axios.get(`/charge/${id}`);
  }

  async changeProductStatus(productId: string, active: boolean) {
    try {
      await this.cashlessAxios.axios.post(`event-user-product/product-status`, { productId, active });
      return await Promise.resolve();
    } catch (e) {
      return await Promise.reject();
    }
  }
}
