import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CurrentUserDto } from '@app/routes/login/login.dto';
import { LoginService } from '@app/routes/login/login.service';

@Injectable({
  providedIn: 'root',
})
export class RouteGuardLogged implements CanActivate {
  currentUser: CurrentUserDto | null;

  constructor(private router: Router, private loginService: LoginService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean | UrlTree {
    let isValid = true;
    if (!!this.loginService.currentUser.value) {
      this.router.navigateByUrl('/');
      isValid = false;
    }
    return isValid;
  }
}
