import { Injectable } from '@angular/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import CashlessAxios from '../hooks/cashless.axios';
import { WalletService } from '@app/routes/wallet/wallet.service';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  platform: string;

  isPushNotificationsAvailable = false;

  constructor(private cashlessAxios: CashlessAxios, private walletService: WalletService) {
    this.platform = Capacitor.getPlatform();
    this.isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');
    this.initializePushToken();
  }

  updatePushToken() {
    if (!this.isPushNotificationsAvailable) {
      return;
    }

    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === 'granted') {
        PushNotifications.register();
      }
    });
  }

  private async initializePushToken() {
    if (!this.isPushNotificationsAvailable) {
      return;
    }

    PushNotifications.addListener('registration', async (token) => {
      if (token.value) {
        await this.cashlessAxios.axios.post(`user/push-notification-token`, {
          token: token.value,
          type: this.platform.toUpperCase(),
        });
        console.log('Registration token: ', token.value);
      }
    });

    PushNotifications.addListener('registrationError', (err) => {
      console.error('Registration error: ', err.error);
    });

    PushNotifications.addListener('pushNotificationReceived', (notification) => {
      this.walletService.walletUpdate();
      console.log('Push notification received: ', notification);
    });

    PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
      this.walletService.walletUpdate();
      console.log('Push notification action performed', notification.actionId, notification.inputValue);
    });

    this.updatePushToken();
  }

  private async getDeliveredNotifications() {
    const notificationList = await PushNotifications.getDeliveredNotifications();
    console.log('delivered notifications', notificationList);
  }
}
