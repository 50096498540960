import { Injectable } from '@angular/core';
import CashlessAxios from '@app/hooks/cashless.axios';
import { CardDataToChargeDto, SellerPixChargeDto } from '@app/routes/wallet/wallet.dto';

@Injectable()
export class AddBalanceService {
  constructor(private cashlessAxios: CashlessAxios) {}

  postCardCharge(data: CardDataToChargeDto) {
    return this.cashlessAxios.axios.post('payment/charge-credit-card/', data);
  }

  picpayCharge(amount: number) {
    return this.cashlessAxios.axios.post('payment/picpay', { value: amount });
  }

  safe2PayPix(amount: number) {
    return this.cashlessAxios.axios.post<{ qrcode: string; copiaecola: string }>('payment/safe2pay-pix', {
      value: amount,
    });
  }

  postPixCharge(amount: number, orderData?: SellerPixChargeDto) {
    return this.cashlessAxios.axios.post(`payment/charge-pix/`, {
      value: amount,
      orderData,
    });
  }

  postPixSellerCharge(data: any) {
    return this.cashlessAxios.axios.post(`seller/charge-pix-with-products/`, data);
  }
}
