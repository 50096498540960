<div class="menu shadow-5" *ngIf="showMenu">
  <a #rla="routerLinkActive" *ngIf="isLoggedIn()" routerLink="/events" routerLinkActive>
    <img *ngIf="!rla.isActive" src="/assets/footer/home.svg" />
    <img *ngIf="rla.isActive" src="/assets/footer/home-color.svg" />
    <span>Início</span>
  </a>
  <a #rla="routerLinkActive" *ngIf="isLoggedIn()" routerLink="/wallet" routerLinkActive>
    <img *ngIf="!rla.isActive" src="/assets/footer/wallet.svg" />
    <img *ngIf="rla.isActive" src="/assets/footer/wallet-color.svg" />
    <span>Carteira</span>
  </a>
  <a #rla="routerLinkActive" *ngIf="isLoggedIn() && showTransactions" routerLink="/seller" routerLinkActive>
    <img *ngIf="!rla.isActive" src="/assets/footer/transactions.svg" />
    <img *ngIf="rla.isActive" src="/assets/footer/transactions-color.svg" />
    <span>Transações</span>
  </a>
  <a #rla="routerLinkActive" *ngIf="isLoggedIn()" routerLink="/notifications" routerLinkActive>
    <img *ngIf="!rla.isActive" src="/assets/footer/bell-off.svg" />
    <img *ngIf="rla.isActive" src="/assets/footer/bell-on.svg" />
    <div class="badge" *ngIf="notificationCount > 0">{{ notificationCount }}</div>
    <span>Notificações</span>
  </a>
  <a #rla="routerLinkActive" *ngIf="isLoggedIn()" routerLink="/my-profile" routerLinkActive>
    <img *ngIf="!rla.isActive" src="/assets/footer/profile.svg" />
    <img *ngIf="rla.isActive" src="/assets/footer/profile-color.svg" />
    <span>Perfil</span>
  </a>
  <a #rla="routerLinkActive" *ngIf="!isLoggedIn()" routerLink="/sign-in" routerLinkActive>
    <img *ngIf="!rla.isActive" src="/assets/footer/profile.svg" />
    <img *ngIf="rla.isActive" src="/assets/footer/profile-color.svg" />
    <span>Login</span>
  </a>
  <a #rla="routerLinkActive" routerLink="/faq" routerLinkActive>
    <img *ngIf="!rla.isActive" src="/assets/footer/faq.svg" />
    <img *ngIf="rla.isActive" src="/assets/footer/faq-color.svg" />
    <span>Dúvidas</span>
  </a>
</div>
