<button mat-icon-button class="dialog-close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<div class="container text-center">
  <div class="transfer-confirm">
    <img src="/assets/add-balance.svg" class="transfer-confirm-image" />
    <p class="transfer-confirm-description">Confirme a transação</p>

    <p class="transfer-confirm-value">{{ transaction.amount | amountBr }}</p>
    <p class="transfer-confirm-to">{{ transaction.fromName }}</p>
    <div class="row">
      <button class="transfer-confirm-button" *ngIf="!transaction.checked" (click)="check(true)">marcar</button>
      <button class="transfer-confirm-button transfer-confirm-button__cancel" *ngIf="transaction.checked" (click)="check(false)">desmarcar</button>
    </div>
  </div>
</div>
