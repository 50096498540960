<button mat-icon-button class="dialog-close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<div class="container text-center">
  <div *ngIf="!transferDone && !userNotFound && !toUser.phone">
    <h1 class="cl-title">Transferir Saldo</h1>

    <form (ngSubmit)="getUser()" [formGroup]="formGroup" class="text-center">
      <div>
        <input
          class="cl-input text-center"
          formControlName="phoneNumber"
          inputmode="numeric"
          mask="(00) 0000-0000||(00) 00000-0000"
          placeholder="(00) 00000-0000"
          type="text"
        />
        <p class="cl-input-helper">digite o celular do destinatário com o DDD</p>
      </div>
      <div>
        <input
          [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
          class="cl-input text-center"
          currencyMask
          formControlName="amount"
          inputmode="numeric"
          type="text"
        />
        <p class="cl-input-helper">digite o valor desejado</p>
      </div>
      <div>
        <button [disabled]="formGroup.invalid" class="cl-button-primary" type="submit">próximo</button>
      </div>
    </form>
  </div>

  <div *ngIf="toUser.phone && !transferDone" class="transfer-confirm">
    <img src="/assets/transfer.svg" class="image" />
    <p class="description">Transferir saldo:</p>
    <div class="badge">
      <i class="icon"></i>
      <p class="text">CONFIRME OS DADOS</p>
    </div>

    <p class="value">R${{ data.value | amountBr }}</p>
    <p class="to">{{ toUser.name }}</p>
    <p class="phone">{{ toUser.phone | phoneNumber }}</p>

    <div class="d-flex justify-content-between align-items-center">
      <button class="button button--cancel" (click)="reset()">Cancelar!</button>
      <button class="button" (click)="transfer()">Transferir!</button>
    </div>
  </div>

  <div *ngIf="transferDone && !error" class="transfer-done">
    <img src="/assets/transfer.svg" class="image" />
    <p class="description">A transferência foi realizada com sucesso! <span class="font-normal">💰</span></p>
  </div>

  <div *ngIf="userNotFound" class="not-found">
    <img src="/assets/no-found.svg" class="image" />
    <p class="description">Destinatário não<br />localizado <span class="font-normal">😰</span></p>
  </div>

  <div *ngIf="transferDone && error" class="not-found">
    <img src="/assets/error.svg" class="image" />
    <p class="description">{{ transferMessage }}</p>
  </div>
</div>
