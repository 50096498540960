import { NgModule, Pipe, PipeTransform } from '@angular/core';
import moment, { Moment } from 'moment';

@Pipe({ name: 'dateBr' })
export class DateBrPipe implements PipeTransform {
  transform(value: Date | Moment): string {
    return moment.utc(value).local().format('DD/MM/YYYY - HH:mm');
  }
}

@Pipe({ name: 'dateTimeSecondBr' })
export class DateTimeSecondBrPipe implements PipeTransform {
  transform(value: Date | Moment): string {
    return moment.utc(value).local().format('DD/MM/YYYY - HH:mm:ss');
  }
}

@Pipe({ name: 'nl2Br' })
export class Nl2BrPipe implements PipeTransform {
  transform(value: string): string {
    return value?.replace(/\n/g, '<br />\n');
  }
}

@Pipe({ name: 'amountBr' })
export class AmountBrPipe implements PipeTransform {
  transform(value: number): string {
    return value.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
}

@Pipe({ name: 'cpf' })
export class CpfPipe implements PipeTransform {
  transform(value: string): string {
    return [value.substr(0, 3), '.', value.substr(3, 3), '.', value.substr(6, 3), '-', value.substr(9, 2)].join('');
  }
}

@Pipe({ name: 'phoneNumber' })
export class PhoneNumberPipe implements PipeTransform {
  transform(value: string): string {
    if (value.length === 10) {
      return ['(', value.substr(0, 2), ') ', value.substr(2, 4), '-', value.substr(6, 4)].join('');
    } else if (value.length === 11) {
      return ['(', value.substr(0, 2), ') ', value.substr(2, 5), '-', value.substr(7, 4)].join('');
    }
    return value;
  }
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function AmountBrTransform(value: number) {
  return value.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

@Pipe({ name: 'spanMoney' })
export class SpanMoneyPipe implements PipeTransform {
  transform(value: number) {
    if (typeof value != 'number') {
      value = Number(value);
    }
    value = value || 0.0;
    const val = value.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).split(',');
    const integer = val[0];
    const decimal = val[1] || '00';

    return `${integer}<span>,${decimal}</span>`;
  }
}

@Pipe({ name: 'replaceBreak' })
export class ReplaceBreakPipe implements PipeTransform {
  transform(html: string) {
    return html.replace(/\\r/g, '').replace(/\\n/g, '<br/><br/>');
  }
}

@Pipe({ name: 'spanMoneySymbol' })
export class SpanMoneySymbolPipe implements PipeTransform {
  transform(value: number) {
    value = value || 0.0;

    const val = value.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).split(',');

    const integer = val[0];
    const decimal = val[1] || '00';

    return `<span class="symbol">R$</span> ${integer}<span class="decimal">,${decimal}</span>`;
  }
}

@NgModule({
  declarations: [DateBrPipe, DateTimeSecondBrPipe, Nl2BrPipe, CpfPipe, PhoneNumberPipe, AmountBrPipe, SpanMoneyPipe, ReplaceBreakPipe, SpanMoneySymbolPipe],
  exports: [DateBrPipe, DateTimeSecondBrPipe, Nl2BrPipe, CpfPipe, PhoneNumberPipe, AmountBrPipe, SpanMoneyPipe, ReplaceBreakPipe, SpanMoneySymbolPipe],
})
export class PipesModule {}
