import { EventsService } from '@app/routes/events/events.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CurrentUserDto } from '@app/routes/login/login.dto';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { LoginService } from '@app/routes/login/login.service';
import { WalletService } from '@app/routes/wallet/wallet.service';
import { Subscription } from 'rxjs';
import { EventDto, EventUserDto, EverUserCartDto } from '../../events/events.dto';
import { Router } from '@angular/router';

@Component({
  selector: 'app-transfer-seller',
  templateUrl: './transfer-seller.component.html',
  styleUrls: ['./transfer-seller.component.scss'],
})
export class TransferSellerComponent implements OnInit, OnDestroy {
  transferDone = false;

  transferError = false;

  userNotFound = false;

  noBalance = false;

  transferMessage = '';

  currentUser: CurrentUserDto | null;

  subscriptionCurrentUser: Subscription;

  toUser: any = { name: '', phone: '', id: '' };

  subscriptionCart: Subscription;

  cart: EverUserCartDto | null = { products: [], subtotal: 0 };

  subscriptionEventUser: Subscription;

  currentEventUser: EventUserDto;

  subscriptionEvent: Subscription;

  currentEvent: EventDto;

  constructor(
    public dialogRef: MatDialogRef<Component>,
    private walletService: WalletService,
    private loginService: LoginService,
    private eventService: EventsService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {}

  async ngOnInit() {
    this.subscriptionCurrentUser = this.loginService.currentUser.subscribe((value) => (this.currentUser = value));

    this.subscriptionCart = this.eventService.shopCart.subscribe((shop) => (this.cart = shop));

    this.subscriptionEvent = this.eventService.currentEvent.subscribe((v) => (this.currentEvent = v));

    this.subscriptionEventUser = this.eventService.currentEventUser.subscribe((value) => {
      if (!value) {
        this.userNotFound = true;
        return;
      }
      this.currentEventUser = value;
    });
  }

  ngOnDestroy() {
    this.subscriptionCurrentUser.unsubscribe();
    this.subscriptionCart.unsubscribe();
    this.subscriptionEventUser.unsubscribe();
    this.subscriptionEvent.unsubscribe();
  }

  async transfer() {
    try {
      const response = await this.walletService.postTransfer({
        toUserId: this.currentEventUser.id,
        amount: this.dialogData?.amount,
        eventId: this.currentEvent?.id,
      });

      this.walletService.walletUpdate();

      this.transferDone = true;
      this.transferMessage = response.data;
    } catch (e) {
      const message = typeof e.response !== 'undefined' ? e.response.data.message : e.message;
      this.transferMessage = message;
      this.transferDone = true;
      this.transferError = true;
    }

    if (this.transferDone && !this.noBalance && !this.transferError) {
      setTimeout(() => {
        this.router.navigateByUrl('/wallet');
      }, 500);
    }
  }

  reset(): void {
    this.dialogRef.close();
  }
}
