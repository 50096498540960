import { Router } from '@angular/router';
import { EventDto } from '@app/routes/events/events.dto';
import { CashierService } from './../../cashier/cashier.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { EventsService } from '../../events/events.service';
import { CurrentUserDto } from '../../login/login.dto';
import { LoginService } from '../../login/login.service';
import { WalletService } from '../../wallet/wallet.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AlertComponent } from '@src/app/components/alert/alert.component';
import { AxiosError } from 'axios';

@Component({
  selector: 'app-cashier',
  templateUrl: './cashier.component.html',
  styleUrls: ['./cashier.component.scss'],
})
export class TransferCashierComponent implements OnInit {
  public formGroup = new FormGroup({
    phoneNumber: new FormControl('', Validators.required),
    amount: new FormControl('', [Validators.required, Validators.min(0.01)]),
  });

  transferDone = false;

  userNotFound = false;

  transferMessage = '';

  error = false;

  data = {
    phone: '',
    name: '',
    value: 0,
  };

  currentUser: CurrentUserDto | null;

  subscriptionCurrentUser: Subscription;

  subscriptionCurrentBalance: Subscription;

  toUser: any = { name: '', phone: '', id: '' };

  currentBalance = 0;

  subscriptionEvent: Subscription;

  currentEvent: EventDto;

  constructor(
    public dialogRef: MatDialogRef<Component>,
    private walletService: WalletService,
    private loginService: LoginService,
    private dialog: MatDialog,
    private eventService: EventsService,
    private cashierService: CashierService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {}

  async ngOnInit() {
    this.subscriptionCurrentUser = this.loginService.currentUser.subscribe((value) => (this.currentUser = value));
    this.subscriptionCurrentBalance = this.walletService.currentBalance.subscribe((value) => {
      this.currentBalance = value;
    });

    this.subscriptionEvent = this.eventService.currentEvent.subscribe((value) => {
      this.currentEvent = value;
    });

    if (this.dialogData?.phone && this.dialogData?.amount) {
      this.formGroup.setValue({
        phoneNumber: this.dialogData.phone,
        amount: this.dialogData.amount ? this.dialogData.amount : 0,
      });
    }
  }

  ngOnDestroy() {
    this.subscriptionCurrentUser.unsubscribe();
    this.subscriptionCurrentBalance.unsubscribe();
  }

  async getUser() {
    if (this.formGroup.invalid) {
      this.dialog.open(AlertComponent, {
        panelClass: ['dialog-primary', 'dialog-page-mobile'],
        width: '95%',
        maxWidth: 328,
        height: 'auto',
        maxHeight: '90vh',
        autoFocus: false,
        disableClose: false,
        data: {
          type: 'error',
          message: `Termine de preencher o formulário.`,
        },
      });
      return;
    }

    this.walletService
      .getToUser(this.formGroup.get('phoneNumber').value)
      .then((result) => {
        if (!result.data.name && !result.data.phone && !result.data.id) {
          this.userNotFound = true;
          return;
        }
        this.eventService.addFriend({ id: result.data.id, name: result.data.name, phone: result.data.phone });
        this.toUser = result.data;
        this.data.value = this.formGroup.get('amount').value;
      })
      .catch(async (error: AxiosError) => {
        this.userNotFound = true;
        await this.reset();
      });
  }

  async transfer() {
    if (this.formGroup.invalid) {
      this.dialog.open(AlertComponent, {
        panelClass: ['dialog-primary', 'dialog-page-mobile'],
        width: '95%',
        maxWidth: 328,
        height: 'auto',
        maxHeight: '90vh',
        autoFocus: false,
        disableClose: false,
        data: {
          type: 'error',
          message: `Termine de preencher o formulário.`,
        },
      });
      return;
    }

    try {
      await this.cashierService
        .sendMoney({
          toUserId: this.toUser.id,
          amount: this.data.value,
          eventId: this.currentEvent.id,
        })
        .then((res) => {
          this.transferDone = true;
          this.transferMessage = res.data;
        })
        .catch((e) => {
          this.transferDone = true;
          this.error = true;
          this.transferMessage = typeof e.response !== 'undefined' ? e.response.data.message : e.message;
        });
    } catch (e) {
      console.error(e);
      this.reset();
    }

    if (this.transferDone && !this.error) {
      setTimeout(() => {
        this.router.navigateByUrl('/cashier/transactions');
      }, 500);
    }
  }

  reset(): void {
    this.toUser = { name: '', phone: '', id: '' };
    this.transferDone = false;
    this.data = {
      phone: '',
      name: '',
      value: 0,
    };
    this.transferMessage = '';
  }
}
