import axios, { AxiosError } from 'axios';
import { Injectable, Injector } from '@angular/core';
import { LoadingService } from '@app/services/loading.service';

@Injectable()
export default class LoadingAxios {
  constructor(private injector: Injector) {}

  public get axios() {
    const customAxios = axios.create();

    const localThis = this;

    customAxios.interceptors.request.use(
      (config) => {
        localThis.totalIncrease();
        return config;
      },
      (error) => {
        localThis.totalDecrease();
        return Promise.reject(error);
      }
    );

    customAxios.interceptors.response.use(
      (response) => {
        localThis.totalDecrease();
        return response;
      },
      (error: AxiosError | any) => {
        localThis.totalDecrease();
        return Promise.reject(error);
      }
    );

    return customAxios;
  }

  totalIncrease() {
    const loadingService = this.injector.get<LoadingService>(LoadingService);
    loadingService.increment('loading-axios');
  }

  totalDecrease() {
    const loadingService = this.injector.get<LoadingService>(LoadingService);
    loadingService.decrement('loading-axios');
  }
}
