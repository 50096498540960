import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { TransactionSellerDto } from '@app/routes/seller/seller.dto';
import { SellerService } from '@app/routes/seller/seller.service';
import { AlertComponent } from '@src/app/components/alert/alert.component';

@Component({
  selector: 'app-transactions-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class TransactionsConfirmComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public transaction: TransactionSellerDto,
    private dialogRef: MatDialogRef<TransactionsConfirmComponent>,
    private sellerService: SellerService,
    private dialog: MatDialog
  ) {}

  async check(checked: boolean) {
    await this.sellerService
      .postTransactionUserMetadata(this.transaction.id, checked)
      .then(() => {
        this.dialogRef.close();
      })
      .catch((error: any) => {
        this.dialog.open(AlertComponent, {
          panelClass: ['dialog-primary', 'dialog-page-mobile'],
          width: '95%',
          maxWidth: 328,
          height: 'auto',
          maxHeight: '90vh',
          autoFocus: false,
          disableClose: false,
          data: {
            type: 'error',
            message: error.data.message,
          },
        });
      });
  }
}
