<div class="container">
  <app-back-button></app-back-button>

  <h1 class="cl-title text-center">Gerar Pedido</h1>

  <div class="search" *ngIf="!!menuList.length">
    <form>
      <input type="text" (input)="search($event)" class="cl-input" placeholder="Busque um produto pelo nome..." />
    </form>
  </div>

  <div class="products" *ngIf="!!menuList.length && !searching.length && !isSearching">
    <ng-container *ngFor="let product of menuList">
      <div class="product" *ngIf="product.active" (click)="cartAction(product, 'add', $event)">
        <div class="content">
          <div class="d-flex align-items-center justify-content-between">
            <p class="name">{{ product.name }}</p>
            <div class="amount" *ngIf="!product.subProducts">
              <span class="currency">R$</span>
              <p class="money" [innerHTML]="product.price | spanMoney"></p>
            </div>
          </div>
        </div>
        <div class="options" *ngIf="!!product.subProducts">
          <div
            class="option"
            *ngFor="let variation of product.subProducts"
            (click)="cartAction(variation, 'add', $event)"
          >
            <div class="infos">
              <p class="name">{{ variation.nameVariation }}</p>
              <div class="amount">
                <span class="currency">R$</span>
                <p class="money" [innerHTML]="variation.price | spanMoney"></p>
              </div>
            </div>
            <div class="cart">
              <div class="minus" (click)="cartAction(variation, 'remove', $event)">
                <mat-icon>remove_circle</mat-icon>
              </div>
              <div class="quantity">{{ cartProductQuantity(variation) }}</div>
              <div class="plus" (click)="cartAction(variation, 'add', $event)">
                <mat-icon>add_circle</mat-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="cart" *ngIf="!product.subProducts">
          <div class="minus" (click)="cartAction(product, 'remove', $event)">
            <mat-icon>remove_circle</mat-icon>
          </div>
          <div class="quantity">{{ cartProductQuantity(product) }}</div>
          <div class="plus" (click)="cartAction(product, 'add', $event)">
            <mat-icon>add_circle</mat-icon>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="products" *ngIf="!!menuList.length && searching.length && isSearching">
    <ng-container *ngFor="let product of searching">
      <div class="product" *ngIf="product.active" (click)="cartAction(product, 'add', $event)">
        <div class="content">
          <div class="d-flex align-items-center justify-content-between">
            <p class="name">{{ product.name }}</p>
            <div class="amount" *ngIf="!product.subProducts">
              <span class="currency">R$</span>
              <p class="money" [innerHTML]="product.price | spanMoney"></p>
            </div>
          </div>
        </div>
        <div class="options" *ngIf="!!product.subProducts">
          <div
            class="option"
            *ngFor="let variation of product.subProducts"
            (click)="cartAction(variation, 'add', $event)"
          >
            <div class="infos">
              <p class="name">{{ variation.nameVariation }}</p>
              <div class="amount">
                <span class="currency">R$</span>
                <p class="money" [innerHTML]="variation.price | spanMoney"></p>
              </div>
            </div>
            <div class="cart">
              <div class="minus" (click)="cartAction(variation, 'remove', $event)">
                <mat-icon>remove_circle</mat-icon>
              </div>
              <div class="quantity">{{ cartProductQuantity(variation) }}</div>
              <div class="plus" (click)="cartAction(variation, 'add', $event)">
                <mat-icon>add_circle</mat-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="cart" *ngIf="!product.subProducts">
          <div class="minus" (click)="cartAction(product, 'remove', $event)">
            <mat-icon>remove_circle</mat-icon>
          </div>
          <div class="quantity">{{ cartProductQuantity(product) }}</div>
          <div class="plus" (click)="cartAction(product, 'add', $event)">
            <mat-icon>add_circle</mat-icon>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="cart" *ngIf="!!menuList.length || (searching.length && !isSearching)">
    <div class="subtotal">
      <div class="amount">
        <span class="currency">R$</span>
        <p class="money" [innerHTML]="cart?.subtotal | spanMoney"></p>
      </div>
      <p class="description">Valor do pedido</p>
    </div>
    <button class="default-button" type="button" [disabled]="!cart?.subtotal" (click)="checkoutDialog()">
      finalizar
    </button>
  </div>
</div>
