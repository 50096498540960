import { Component } from '@angular/core';
import { environment } from '@src/environments/environment';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent {
  version = environment.version;
  gitCommitHash = environment.gitCommitHash;
}
