<button mat-icon-button class="dialog-close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<div class="container text-center">
  <div *ngIf="currentEventUser?.id && !transferDone && !noBalance" class="transfer-confirm">
    <img src="/assets/transfer.svg" class="image" />
    <p class="description">Transferir saldo:</p>
    <div class="badge">
      <i class="icon"></i>
      <p class="text">CONFIRME OS DADOS</p>
    </div>

    <p class="value">R${{ dialogData?.amount | amountBr }}</p>
    <p class="to">{{ currentEventUser?.name }}</p>
    <p class="phone">{{ currentEventUser?.phone | phoneNumber }}</p>

    <div class="d-flex justify-content-between align-items-center">
      <button class="button button--cancel" (click)="reset()">Cancelar!</button>
      <button class="button" (click)="transfer()">Transferir!</button>
    </div>
  </div>

  <div *ngIf="transferDone && !noBalance && !transferError" class="transfer-done">
    <img src="/assets/transfer.svg" class="image" />
    <p class="description">A transferência foi realizada com sucesso! <span class="font-normal">💰</span></p>
  </div>

  <div *ngIf="userNotFound && !noBalance" class="not-found">
    <img src="/assets/no-found.svg" class="image" />
    <p class="description">Destinatário não<br />localizado <span class="font-normal">😰</span></p>
  </div>

  <div *ngIf="noBalance && !transferDone" class="no-balance">
    <img src="/assets/transaction-error.svg" class="image" />
    <p class="description">Saldo insuficiente<br />na carteira <span class="font-normal">😰</span></p>
  </div>

  <div class="not-send" *ngIf="transferDone && transferError">
    <div class="container">
      <div class="title">
        <img src="assets/transaction-error.svg" />
        <h2>{{ transferMessage }} <span class="font-normal">😰</span></h2>
      </div>
    </div>
  </div>
</div>
