import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { SellerService } from '../seller.service';
import { toDataURL } from 'qrcode';
import { LoadingService } from '@app/services/loading.service';

@Component({
  selector: 'app-seller-create-order-pix',
  templateUrl: './pix.component.html',
  styleUrls: ['./pix.component.scss'],
})
export class SellerPixComponent implements OnInit, OnDestroy {
  id: string | null;

  charge: {
    amount: number;
    paymentData: {
      qrcode: string;
    };
    qrcodeImage: string;
  };

  loading = true;

  routeSubscription: Subscription;

  loadingSubscription: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private sellerService: SellerService,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.loadingSubscription = this.loadingService.loading.subscribe((val) => {
      this.loading = val;
    });

    this.routeSubscription = this.activatedRoute.paramMap.subscribe((params) => {
      this.id = params.get(`id`);

      this.loadPix();
    });
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }

  loadPix() {
    if (this.id) {
      this.sellerService.loadCharge(this.id).then(async (res) => {
        this.charge = {
          ...res.data,
          qrcodeImage: await toDataURL(res.data.paymentData.qrcode),
        };
      });
    }
  }
}
