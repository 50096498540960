import { TransferModule } from './routes/transfer/transfer.module';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MyProfileComponent } from './routes/my-profile/my-profile.component';
import { FaqComponent } from '@app/routes/faq/faq.component';
import { RouteGuard } from '@app/hooks/route-guard.can-activate';
import { NotificationsComponent } from './routes/notifications/notifications.component';
import { DeleteAccountComponent } from './routes/my-profile/delete-account/delete-account.component';

const routes: Routes = [
  {
    path: 'my-profile',
    component: MyProfileComponent,
    canActivate: [RouteGuard],
  },
  {
    path: 'delete-account',
    component: DeleteAccountComponent,
    canActivate: [RouteGuard],
  },
  {
    path: 'notifications',
    component: NotificationsComponent,
    canActivate: [RouteGuard],
  },
  {
    path: 'transfer',
    component: TransferModule,
    canActivate: [RouteGuard],
  },
  {
    path: 'faq',
    component: FaqComponent,
  },
  {
    path: 'login',
    loadChildren: () => import('./routes/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'wallet',
    loadChildren: () => import('./routes/wallet/wallet.module').then((m) => m.WalletModule),
  },
  {
    path: 'events',
    loadChildren: () => import('./routes/events/events.module').then((m) => m.EventsModule),
  },
  {
    path: 'seller',
    loadChildren: () => import('./routes/seller/seller.module').then((m) => m.SellerModule),
  },
  {
    path: 'cashier',
    loadChildren: () => import('./routes/cashier/cashier.module').then((m) => m.CashierModule),
  },
  {
    path: '**',
    redirectTo: '/events',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
