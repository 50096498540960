import { AlertComponent } from './components/alert/alert.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MyProfileComponent } from './routes/my-profile/my-profile.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterComponent } from './components/footer/footer.component';
import { FaqComponent } from './routes/faq/faq.component';
import { PipesModule } from '@app/pipes/pipes';
import { NgxMaskModule } from 'ngx-mask';
import { NgxCurrencyModule } from 'ngx-currency';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LoginService } from './routes/login/login.service';
import { EventsService } from './routes/events/events.service';
import { CommonModule } from '@angular/common';
import CashlessAxios from '@app/hooks/cashless.axios';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoadingInterceptor } from '@app/hooks/loading.interceptor';
import { LoadingService } from '@app/services/loading.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { IonicModule, isPlatform } from '@ionic/angular';
import { SellerModule } from '@app/routes/seller/seller.module';
import { WalletService } from '@app/routes/wallet/wallet.service';
import { RouteGuard } from '@app/hooks/route-guard.can-activate';
import { RouteGuardLogged } from '@app/hooks/route-guard-logged.can-activate';
import { MatButtonModule } from '@angular/material/button';
import { PrivacyPage } from './routes/legal/privacy/privacy.component';
import { BackButtonModule } from './components/back-button/back-button.module';
import LoadingAxios from '@app/hooks/loading.axios';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NotificationsService } from './routes/notifications/notifications.service';
import { NotificationsComponent } from './routes/notifications/notifications.component';
import { GoogleTagManagerService } from './services/google-tag-manager.service';
import { DeleteAccountComponent } from './routes/my-profile/delete-account/delete-account.component';

@NgModule({
  declarations: [
    AppComponent,
    MyProfileComponent,
    DeleteAccountComponent,
    FooterComponent,
    FaqComponent,
    AlertComponent,
    PrivacyPage,
    NotificationsComponent,
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      animated: !isPlatform('mobileweb'),
      mode: 'md',
      statusTap: true,
    }),
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    NgxCurrencyModule,
    FormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    MatDialogModule,
    MatIconModule,
    SellerModule,
    PipesModule,
    MatButtonModule,
    BackButtonModule,
    MatProgressSpinnerModule,
  ],
  providers: [
    CashlessAxios,
    LoadingAxios,
    RouteGuard,
    RouteGuardLogged,
    LoginService,
    EventsService,
    LoadingService,
    WalletService,
    FooterComponent,
    NotificationsService,
    GoogleTagManagerService,
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
