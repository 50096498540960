import { EventsService } from '@app/routes/events/events.service';
import { LoginService } from '@app/routes/login/login.service';
import { Injectable } from '@angular/core';
import { CurrentUserDto } from '@src/app/routes/login/login.dto';
import { BehaviorSubject, Subscription } from 'rxjs';
import { EventDto } from '@src/app/routes/events/events.dto';

@Injectable({
  providedIn: 'root',
})
export class FooterService {
  isCashier = new BehaviorSubject<boolean>(false);

  subscriptionUser: Subscription;

  currentUser: CurrentUserDto | null = null;

  subscriptionEvent: Subscription;

  currentEvent: EventDto;

  constructor(private loginService: LoginService, private eventService: EventsService) {
    this.subscriptionUser = this.loginService.currentUser.subscribe((value) => {
      this.currentUser = value;
    });

    this.subscriptionEvent = this.eventService.currentEvent.subscribe((value) => {
      this.currentEvent = value;
    });
  }

  checkIsCashier() {
    const check =
      this.currentUser &&
      this.currentUser?.userRole.find((r) => r.eventId === this.currentEvent?.id && r.role === 'cashier')
        ? false
        : true;

    this.isCashier.next(check);
  }
}
