<div class="container">
  <app-back-button></app-back-button>
  <div class="seller">
    <div class="header">
      <div class="icon"><img src="assets/menu.svg" /></div>
      <h2 class="title">Editar cardápio</h2>
      <div class="badge">
        <i class="icon"></i>
        <p class="text">CLIQUE PARA HABILITAR/DESABILITAR</p>
      </div>
    </div>
    <div class="products">
      <div class="product" *ngFor="let product of menuList" (click)="changeProductStatus(product)">
        <p class="name" class="{{ !product.active ? 'inactive' : '' }}">
          {{ product.name }} {{ product?.nameVariation }}
        </p>
      </div>
    </div>
  </div>
</div>
