import axios, { AxiosError } from 'axios';
import { Injectable, Injector } from '@angular/core';
import { LoadingService } from '@app/services/loading.service';
import { LoginService } from '@app/routes/login/login.service';

@Injectable()
export default class CashlessAxios {
  constructor(private injector: Injector) {}

  public get axios() {
    const customAxios = axios.create({
      baseURL: (window as any)?.apiUrl || 'https://api.cashless-app.com.br',
    });

    const localThis = this;

    customAxios.interceptors.request.use(
      (config) => {
        localThis.totalIncrease();

        const token = localStorage.getItem('jwt') || null;
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        localThis.totalDecrease();
        return Promise.reject(error);
      }
    );

    customAxios.interceptors.response.use(
      (response) => {
        localThis.totalDecrease();

        if (!!response.headers['x-refreshed-token']) {
          const loginService = localThis.injector.get<LoginService>(LoginService);
          loginService.setUserByJwt(response.headers['x-refreshed-token']);
        }

        return response;
      },
      (error: AxiosError | any) => {
        if (error?.response?.status === 401) {
          if (error?.response?.data?.message) {
            alert(error?.response?.data?.message);
          }
          const loginService = localThis.injector.get<LoginService>(LoginService);
          loginService.logout();
        }

        localThis.totalDecrease();
        return Promise.reject(error);
      }
    );

    return customAxios;
  }

  totalIncrease() {
    const loadingService = this.injector.get<LoadingService>(LoadingService);
    loadingService.increment('cashless-axios');
  }

  totalDecrease() {
    const loadingService = this.injector.get<LoadingService>(LoadingService);
    loadingService.decrement('cashless-axios');
  }
}
