<div class="container">
  <div class="place-menu" routerLink="/seller/menu" *ngIf="currentEvent">Editar Cardápio</div>
  <div class="place-menu place-menu-left" routerLink="/seller/create-order" *ngIf="currentEvent">Gerar Pedido</div>
  <div class="place-menu" routerLink="/events" *ngIf="!currentEvent">Selecione um Evento</div>

  <h1 class="cl-title mt-5 text-center">Transações</h1>

  <div class="lastUpdate" *ngIf="lastUpdate">
    <span class="date">Última atualização: {{ lastUpdate | dateTimeSecondBr }}</span>
    <button mat-mini-fab class="reload" (click)="loadTransactions()"><mat-icon>sync</mat-icon></button>
  </div>

  <p *ngIf="!sellerTransactions?.length" class="text-center">Nenhuma transação.</p>

  <div class="transactions">
    <ng-container *ngFor="let transaction of sellerTransactions">
      <div
        class="transaction"
        (click)="confirmDialog(transaction)"
        *ngIf="transaction.type === transactionSellerTypeEnum.Transfer"
      >
        <div class="top">
          <div class="info">
            <p class="date">{{ transaction.date | dateBr }}</p>
            <p class="name">{{ transaction.fromName }}</p>
          </div>
          <p class="price">R${{ transaction.amount | amountBr }}</p>

          <img src="/assets/check.svg" *ngIf="transaction.checked" />
          <div class="new" *ngIf="!transaction.checked"></div>
        </div>

        <p class="no-description" *ngIf="!transaction.subdescription.length">Sem descrição</p>

        <ng-container *ngIf="transaction?.subdescription">
          <div class="product" *ngFor="let product of transaction.subdescription">
            <p>{{ product.quantity }}x</p>
            <p>{{ product.name }} {{ !!product.nameVariation ? ' - ' + product.nameVariation : '' }}</p>
            <div class="amount">
              <span class="currency">R$</span>
              <p class="money" [innerHTML]="product.price * product.quantity | spanMoney"></p>
            </div>
          </div>
        </ng-container>
      </div>

      <div
        class="transaction charge-active"
        (click)="navigateToCharge(transaction)"
        *ngIf="transaction.type === transactionSellerTypeEnum.ChargeActive"
      >
        <div class="top">
          <div class="info">
            <p class="date">{{ transaction.date | dateBr }}</p>
            <p class="name">Depósito Pix</p>
          </div>
          <p class="price">R${{ transaction.amount | amountBr }}</p>
        </div>

        <p class="charge-status">Pendente <mat-icon>close</mat-icon></p>

        <ng-container *ngIf="transaction?.subdescription">
          <div class="product" *ngFor="let product of transaction.subdescription">
            <p>{{ product.quantity }}x</p>
            <p>{{ product.name }} {{ !!product.nameVariation ? ' - ' + product.nameVariation : '' }}</p>
            <div class="amount">
              <span class="currency">R$</span>
              <p class="money" [innerHTML]="product.price * product.quantity | spanMoney"></p>
            </div>
          </div>
        </ng-container>
      </div>

      <div
        class="transaction charge-paid"
        *ngIf="transaction.type === transactionSellerTypeEnum.ChargePaid"
      >
        <div class="top">
          <div class="info">
            <p class="date">{{ transaction.date | dateBr }}</p>
            <p class="name">Depósito Pix</p>
          </div>
          <p class="price">R${{ transaction.amount | amountBr }}</p>
        </div>

        <p class="charge-status">Pago <mat-icon>done</mat-icon></p>

        <ng-container *ngIf="transaction?.subdescription">
          <div class="product" *ngFor="let product of transaction.subdescription">
            <p>{{ product.quantity }}x</p>
            <p>{{ product.name }} {{ !!product.nameVariation ? ' - ' + product.nameVariation : '' }}</p>
            <div class="amount">
              <span class="currency">R$</span>
              <p class="money" [innerHTML]="product.price * product.quantity | spanMoney"></p>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
