import { Injectable, OnDestroy } from '@angular/core';
import CashlessAxios from '@app/hooks/cashless.axios';
import { AxiosError } from 'axios';
import { BehaviorSubject, Subscription } from 'rxjs';
import { NotificationDto } from './notifications.dto';
import { LoginService } from '@app/routes/login/login.service';

@Injectable()
export class NotificationsService implements OnDestroy {
  unreadNotifications = new BehaviorSubject<number>(0);

  subscriptionCurrentUser: Subscription;

  constructor(private cashlessAxios: CashlessAxios, private loginService: LoginService) {
    this.subscriptionCurrentUser = loginService.currentUser.subscribe((value) => {
      if (!!value) {
        this.getUnreadCount();
      }
    });
  }

  ngOnDestroy() {
    this.subscriptionCurrentUser.unsubscribe();
  }

  async getNotifications() {
    try {
      const notifications = await this.cashlessAxios.axios.get<NotificationDto[]>(`user-notification`);
      await this.markRead();

      return notifications?.data;
    } catch (e) {}
  }

  async getUnreadCount() {
    try {
      const count = await this.cashlessAxios.axios.get(`user-notification/unread`);
      this.unreadNotifications.next(count.data);
    } catch (e) {}
  }

  async markRead() {
    try {
      const data = await this.cashlessAxios.axios.post(`user-notification`);

      await this.getUnreadCount();

      return data;
    } catch (e: AxiosError | any) {
      console.log(e.message || e);
    }
  }
}
