import { LoginService } from '@app/routes/login/login.service';
import { EventsService } from '@app/routes/events/events.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { SellerService } from '@app/routes/seller/seller.service';
import { Subscription } from 'rxjs';
import { EventDto, EventUserProductDto } from '../../events/events.dto';
import { CurrentUserDto } from '../../login/login.dto';

@Component({
  selector: 'app-seller-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class SellerMenuComponent implements OnInit, OnDestroy {
  subscriptionEvent: Subscription;

  currentEvent: EventDto;

  subscriptionCurrentUser: Subscription;

  currentUser: CurrentUserDto;

  menuList: EventUserProductDto[] | null = [];

  constructor(
    private sellerService: SellerService,
    private eventService: EventsService,
    private loginService: LoginService
  ) {}

  ngOnInit() {
    this.subscriptionEvent = this.eventService.currentEvent.subscribe((value) => {
      this.currentEvent = value;
    });

    this.subscriptionCurrentUser = this.loginService.currentUser.subscribe((value) => {
      this.currentUser = value;
    });

    this.getUserProducts(this.currentEvent?.id, this.currentUser?.id);
  }

  ngOnDestroy() {
    this.subscriptionEvent.unsubscribe();
    this.subscriptionCurrentUser.unsubscribe();
  }

  getUserProducts(eventId: string, userId: string) {
    this.eventService.getUserProducts(eventId, userId).then((result) => {
      this.menuList = result.data.sort((a, b) => a.name.localeCompare(b.name));
    });
  }

  async changeProductStatus(product: EventUserProductDto) {
    await this.sellerService.changeProductStatus(product.id, !product.active);
    const pdt = this.menuList.find((p) => p.id === product.id);
    pdt.active = !pdt.active;
  }
}
