import { EventDto, EventUserDto, EventUserProductDto, EverUserCartDto, FriendListDto } from './events.dto';
import { Injectable } from '@angular/core';
import CashlessAxios from '@app/hooks/cashless.axios';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class EventsService {
  currentEvent = new BehaviorSubject<EventDto>(null);

  currentEventUser = new BehaviorSubject<EventUserDto>(null);

  friendList = new BehaviorSubject<FriendListDto[]>([]);

  shopCart = new BehaviorSubject<EverUserCartDto>(null);

  constructor(private cashlessAxios: CashlessAxios) {
    let event: EventDto = null;
    event = JSON.parse(localStorage.getItem('selected-event')) || null;
    this.currentEvent.next(event);

    this.currentEvent.subscribe((value) => {
      localStorage.setItem('selected-event', JSON.stringify(value));
    });

    let friends: FriendListDto[] = [];
    friends = JSON.parse(localStorage.getItem('friend-list')) || [];
    this.friendList.next(friends);

    this.friendList.subscribe((value) => {
      localStorage.setItem('friend-list', JSON.stringify(value));
    });
  }

  getUsers(eventId: string) {
    return this.cashlessAxios.axios.get<EventUserDto[]>(`event-user/${eventId}`);
  }

  getActiveEvents() {
    return this.cashlessAxios.axios.get<EventDto[]>(`event/active`);
  }

  getFutureEvents() {
    return this.cashlessAxios.axios.get<EventDto[]>(`event/future`);
  }

  getUserProducts(eventId: string, userId: string) {
    return this.cashlessAxios.axios.post<EventUserProductDto[]>(`event-user-product`, {
      userId,
      eventId,
    });
  }

  changeCurrentEvent(event: EventDto) {
    this.currentEvent.next(event);
  }

  changeCurrentEventUser(shop: EventUserDto) {
    this.currentEventUser.next(shop);
  }

  clear() {
    this.currentEvent.next(null);
    this.currentEventUser.next(null);
    this.shopCart.next(null);
    this.friendList.next([]);
  }

  clearCart() {
    this.shopCart.next({
      products: this.shopCart.value.products.map((p) => (p.quantity > 0 ? { ...p, quantity: 0 } : p)),
      subtotal: 0,
    });
  }

  addFriend(friend: FriendListDto) {
    const friends = this.friendList.value;
    if (friends.findIndex((f) => f.id === friend.id) === -1) friends.push(friend);

    this.friendList.next(friends);
  }
}
