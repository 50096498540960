<div class="container text-center">
  <app-back-button></app-back-button>

  <h1 class="cl-title">Pix</h1>

  <div class="title">
    <h2 class="cl-title">Valor informado:</h2>
    <p>R$ {{ charge.amount | amountBr }}</p>
  </div>

  <div *ngIf="!loading">
    <div>
      <p>Mostre o QR Code abaixo para o seu cliente pagar.</p>
      <p>
        <img [src]="charge.qrcodeImage" alt="QR Code Pix" />
      </p>
    </div>

    <hr class="cl-hr" />

    <div>
      <p>Copie e envie o código de pagamento abaixo.</p>
      <textarea autosize class="code" readonly>{{ charge.paymentData.qrcode }}</textarea>
      <p>
        <button [cbContent]="charge.paymentData.qrcode" class="cl-button-primary" ngxClipboard>
          copiar código pix
        </button>
      </p>
      <p>O código é válido por 15 minutos.</p>
    </div>
  </div>
</div>
