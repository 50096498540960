import { WalletService } from './../../../wallet/wallet.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventsService } from '@app/routes/events/events.service';
import { EventDto, EventUserDto, EventUserProductDto, EverUserCartDto } from '@app/routes/events/events.dto';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddBalanceService } from '@src/app/routes/wallet/add-balance/add-balance.service';
import { LoginService } from '@src/app/routes/login/login.service';

@Component({
  selector: 'app-event-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class SellerOrderCheckoutComponent implements OnDestroy, OnInit {
  subscriptionEvent: Subscription;

  currentEvent: EventDto;

  currentEventUser: EventUserDto;

  menuList: EventUserProductDto[] | null = [];

  loading = false;

  eventId: string;

  eventUserId: string;

  subscriptionCart: Subscription;

  cart: EverUserCartDto | null = { products: [], subtotal: 0 };

  transferDone = false;

  transferError = false;

  transferMessage: any;

  subscriptionBalance: Subscription;

  currentBalance: number;

  constructor(
    private router: Router,
    private eventService: EventsService,
    private walletService: WalletService,
    private paymentService: AddBalanceService,
    private activatedRoute: ActivatedRoute,
    private loginService: LoginService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<Component>
  ) {}

  ngOnInit() {
    this.subscriptionEvent = this.eventService.currentEvent.subscribe((value) => {
      this.currentEvent = value;
    });

    this.subscriptionEvent = this.loginService.currentUser.subscribe((value) => {
      this.currentEventUser = value;
    });

    this.subscriptionCart = this.eventService.shopCart.subscribe((shop) => {
      this.cart = shop;
    });

    this.subscriptionBalance = this.walletService.currentBalance.subscribe((balance) => {
      this.currentBalance = balance;
    });
  }

  ngOnDestroy() {
    this.subscriptionEvent.unsubscribe();
    this.subscriptionCart.unsubscribe();
    this.subscriptionBalance.unsubscribe();
  }

  async generatePixCharge() {
    this.loading = true;

    try {
      const filteredProducts = this.cart.products.filter((product) => product?.quantity > 0);
      const amount = this.cart.subtotal;
      const products = filteredProducts.map((product) => ({
        id: product.id,
        quantity: product.quantity,
      }));

      const response = await this.paymentService.postPixSellerCharge({
        amount,
        products,
        eventId: this.currentEvent.id,
      });

      this.dialogRef.close();
      this.router.navigateByUrl(`/seller/pix/${response.data.id}`);
    } catch (e) {
      const message = typeof e.response !== 'undefined' ? e.response.data.message : e.message;
      this.transferMessage = message;

      this.transferDone = true;
      this.transferError = true;
    }
  }
}
