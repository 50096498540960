export enum TransactionSellerTypeEnum {
  Transfer = 'TRANSFER',
  ChargeActive = 'CHARGE_ACTIVE',
  ChargePaid = 'CHARGE_PAID',
}

export interface TransactionSellerDto {
  id: string;
  fromUserId: string;
  fromName: string;
  fromPhone: string;
  toUserId: string;
  toName: string;
  toPhone: string;
  amount: number;
  date: Date;
  description: string;
  subdescription?: any;
  type: TransactionSellerTypeEnum;
  checked: string;
}
