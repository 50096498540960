import { SellerMenuComponent } from './menu/menu.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SellerService } from '@app/routes/seller/seller.service';
import { TransactionsComponent } from '@app/routes/seller/transactions/transactions.component';
import { TransactionsConfirmComponent } from '@app/routes/seller/transaction-confirm/confirm.component';
import { RouterModule, Routes } from '@angular/router';
import { RouteGuard } from '@app/hooks/route-guard.can-activate';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { PipesModule } from '@app/pipes/pipes';
import { MatButtonModule } from '@angular/material/button';
import { BackButtonModule } from '@src/app/components/back-button/back-button.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AvatarModule } from 'ngx-avatars';
import { NgxCurrencyModule } from 'ngx-currency';
import { SellerCreateOrderComponent } from './create-order/create-order.component';
import { SellerOrderCheckoutComponent } from './create-order/checkout/checkout.component';
import { AddBalanceService } from '../wallet/add-balance/add-balance.service';
import { SellerPixComponent } from './pix/pix.component';
import { ClipboardModule } from 'ngx-clipboard';
import { AutosizeModule } from 'ngx-autosize';

const routes: Routes = [
  {
    path: '',
    component: TransactionsComponent,
    canActivate: [RouteGuard],
  },
  {
    path: 'menu',
    component: SellerMenuComponent,
    canActivate: [RouteGuard],
  },
  {
    path: 'create-order',
    component: SellerCreateOrderComponent,
    canActivate: [RouteGuard],
  },
  {
    path: 'pix/:id',
    component: SellerPixComponent,
    canActivate: [RouteGuard],
  },
];

@NgModule({
  declarations: [
    TransactionsComponent,
    TransactionsConfirmComponent,
    SellerMenuComponent,
    SellerCreateOrderComponent,
    SellerOrderCheckoutComponent,
    SellerPixComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    MatIconModule,
    MatDialogModule,
    PipesModule,
    MatButtonModule,
    BackButtonModule,
    FormsModule,
    NgxCurrencyModule,
    ReactiveFormsModule,
    AvatarModule,
    ClipboardModule,
    AutosizeModule,
  ],
  exports: [RouterModule],
  providers: [SellerService, AddBalanceService],
})
export class SellerModule {}
