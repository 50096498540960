<div class="container">
  <h1 class="cl-title text-center">Notificações</h1>

  <ng-container *ngIf="!loading">
    <div class="notifications" *ngIf="notifications?.length > 0">
      <div
        class="notification"
        *ngFor="let notification of notifications"
        (click)="notificationAction(notification)"
      >
        <div class="icon" *ngIf="!notification?.payload?.image">
          <img src="assets/footer/bell-{{ notification.read ? 'off' : 'on' }}.svg" />
        </div>
        <div class="img" *ngIf="!!notification?.payload?.image">
          <img src="{{ notification.payload.image }}" />
        </div>
        <div class="infos">
          <p class="title">{{ notification.title }}</p>
          <p class="description">{{ notification.body }}</p>
        </div>
        <div class="dot">
          <mat-icon *ngIf="notification.read">check_circle</mat-icon>
          <mat-icon *ngIf="!notification.read">circle</mat-icon>
        </div>
      </div>
    </div>

    <div class="not-found" *ngIf="notifications?.length === 0">
      <p>Nenhuma notificação encontrada.</p>
    </div>
  </ng-container>
</div>
