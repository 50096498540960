<div class="container">
  <h1 class="cl-title text-center">Excluir Conta</h1>

  <p>Para excluir sua conta, entre em contato conosco usando uma das formas de contato a baixo:</p>

  <table>
    <tr>
      <td>Telefone:</td>
      <td>(61) 99304-2324</td>
    </tr>
    <tr>
      <td>Email:</td>
      <td class="email">suporte@bebadoquadrado.com.br</td>
    </tr>
  </table>

  <hr class="cl-hr" />

  <p class="text-end d-flex flex-column">
    <a
      href="https://wa.me/5561993042324?text=Gostaria%20de%20excluir%20minha%20conta"
      target="_blank"
      class="cl-button-wpp mb-3"
    >
      Entre em contato por WhatsApp
    </a>

    <a
      href="mailto:suporte@bebadoquadrado.com.br?subject=Gostaria%20de%20excluir%20minha%20conta"
      target="_blank"
      class="cl-button-primary mb-3"
    >
      Entre em contato por Email
    </a>

    <a href="tel:61993042324" target="_blank" class="cl-button-primary mb-3">Entre em contato por Telefone</a>
  </p>
</div>
