import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadingService } from '@src/app/services/loading.service';
import { Subscription } from 'rxjs';
import { NotificationDto } from './notifications.dto';
import { NotificationsService } from './notifications.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  notifications: NotificationDto[] = [];

  subscriptionLoading: Subscription;

  loading = true;

  constructor(private notificationsService: NotificationsService, private loadingService: LoadingService) {}

  ngOnInit() {
    this.subscriptionLoading = this.loadingService.loading.subscribe((l) => (this.loading = l));

    this.notificationsService.getNotifications().then((value) => {
      this.notifications = value;
    });
  }

  ngOnDestroy() {
    this.subscriptionLoading.unsubscribe();
  }

  notificationAction(notification: NotificationDto) {
    if (notification?.payload?.action?.clickUrl) {
      window.open(notification?.payload?.action?.clickUrl);
    }
  }
}
