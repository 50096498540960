import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { RouteGuard } from '@app/hooks/route-guard.can-activate';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { PipesModule } from '@app/pipes/pipes';
import { TransferDefaultComponent } from './default/transfer-default.component';
import { TransferSellerComponent } from './seller/transfer-seller.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgxMaskModule } from 'ngx-mask';
import { TransferCashierComponent } from './cashier/cashier.component';

const routes: Routes = [
  {
    path: '',
    component: TransferDefaultComponent,
    canActivate: [RouteGuard],
  },
  {
    path: 'seller',
    component: TransferSellerComponent,
    canActivate: [RouteGuard],
  },
  {
    path: 'cashier',
    component: TransferCashierComponent,
    canActivate: [RouteGuard],
  },
];

@NgModule({
  declarations: [TransferDefaultComponent, TransferSellerComponent, TransferCashierComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    PipesModule,
    MatIconModule,
    FormsModule,
    NgxCurrencyModule,
    ReactiveFormsModule,
    MatDialogModule,
    NgxMaskModule.forRoot(),
  ],
  exports: [RouterModule],
  providers: [],
})
export class TransferModule {}
