import { EventsService } from '@app/routes/events/events.service';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CurrentUserDto, IDeviceInfo, SignUpUserDto } from './login.dto';
import CashlessAxios from '@app/hooks/cashless.axios';
import { Router } from '@angular/router';
import { AxiosError } from 'axios';
import { WalletService } from '@app/routes/wallet/wallet.service';
import { PushNotificationService } from '@src/app/services/push-notification.service';
import { GoogleTagManagerService } from '@src/app/services/google-tag-manager.service';
import { Device } from '@capacitor/device';
import { DeviceInfo } from '@capacitor/device/dist/esm/definitions';

interface SignInUpResponseDto {
  response: string;
  jwt?: string;
}

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  currentUser = new BehaviorSubject<CurrentUserDto | null>(null);

  constructor(
    private injector: Injector,
    private cashlessAxios: CashlessAxios,
    private router: Router,
    private googleTagManagerService: GoogleTagManagerService,
    private walletService: WalletService,
    private eventService: EventsService,
    private pushNotificationService: PushNotificationService
  ) {
    this.loadUserFromLocalStorage();
  }

  async login(phoneNumber: string, confirmationCode: string): Promise<any> {
    let deviceInfo: DeviceInfo;
    try {
      deviceInfo = await Device.getInfo();
    } catch (e) {}

    const data: { phoneNumber: string; code: string; deviceInfo: IDeviceInfo } = {
      phoneNumber,
      code: confirmationCode,
      deviceInfo: {
        name: deviceInfo?.name,
        manufacturer: deviceInfo?.manufacturer,
        model: deviceInfo?.model,
        platform: deviceInfo?.platform,
        osVersion: deviceInfo?.osVersion,
      },
    };

    try {
      const result = await this.cashlessAxios.axios.post<SignInUpResponseDto>('login/signin-code', data);
      const gtmTag = {
        event: 'user-login-submit',
        method: 'onsite',
      };

      this.googleTagManagerService.pushTag(gtmTag);

      if (result.data.response === 'success') {
        this.setUserByJwt(result.data.jwt);

        this.walletService.walletUpdate();
        this.pushNotificationService.updatePushToken();

        const loginSuccess = {
          event: 'loginSuccess',
          method: 'onsite',
          currentUser: this.currentUser,
        };

        this.googleTagManagerService.pushTag(loginSuccess);
      }

      return await Promise.resolve(result.data);
    } catch (e: AxiosError | any) {
      this.currentUser.next(null);
      localStorage.removeItem('jwt');

      const loginError = {
        event: 'loginError',
        method: 'onsite',
        validationError: e.response,
      };

      this.googleTagManagerService.pushTag(loginError);

      return Promise.reject(e.response || e);
    }
  }

  setUserByJwt(jwt: string) {
    localStorage.setItem('jwt', jwt);
    const jwtData: CurrentUserDto = this.decodeJwt(jwt);
    this.currentUser.next(jwtData);
  }

  async signUp(data: SignUpUserDto) {
    try {
      const result = await this.cashlessAxios.axios.post<SignInUpResponseDto>('login/sign-up', data);

      const signupSubmit = {
        event: 'signupSubmit',
        method: 'onsite',
      };

      this.googleTagManagerService.pushTag(signupSubmit);

      if (result.data.response === 'success') {
        const jwtData: CurrentUserDto = this.decodeJwt(result.data.jwt);

        localStorage.setItem('jwt', result.data.jwt);
        this.currentUser.next(jwtData);

        this.walletService.walletUpdate();
        this.pushNotificationService.updatePushToken();

        const signupSuccess = {
          event: 'signupSuccess',
          method: 'onsite',
          currentUser: this.currentUser,
        };

        this.googleTagManagerService.pushTag(signupSuccess);

        return await Promise.resolve(result.data.response);
      }
    } catch (e: AxiosError | any) {
      this.currentUser.next(null);
      localStorage.removeItem('jwt');

      const signupError = {
        event: 'signupError',
        method: 'onsite',
        validationError: e.response,
      };

      this.googleTagManagerService.pushTag(signupError);

      return Promise.reject(e.response || e);
    }
  }

  loginCode(phoneNumber: string) {
    return this.cashlessAxios.axios.post('login/request-code-sms', { phoneNumber });
  }

  requestCodeWhatsapp(phoneNumber: string) {
    return this.cashlessAxios.axios.post('login/request-code-whatsapp', { phoneNumber });
  }

  async logout() {
    localStorage.clear();

    this.currentUser.next(null);
    this.eventService.currentEvent.next(null);

    //this.walletService.walletUpdate();
    this.pushNotificationService.updatePushToken();

    await this.router.navigateByUrl('/login');

    location.reload();
  }

  private loadUserFromLocalStorage() {
    try {
      const jwt = localStorage.getItem('jwt') || null;
      const jwtData: CurrentUserDto = this.decodeJwt(jwt);
      this.currentUser.next(jwtData);
    } catch (e) {
      this.currentUser.next(null);
      localStorage.removeItem('jwt');
    }
  }

  private decodeJwt(jwt): any {
    const base64Url = jwt.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );

    return JSON.parse(jsonPayload);
  }
}
