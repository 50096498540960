<button mat-icon-button class="dialog-close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<div class="confirm" *ngIf="!transferDone && !transferError">
  <div class="container">
    <div class="title">
      <img src="assets/menu.svg" />
      <h2>Confirme seu pedido</h2>
    </div>

    <div class="products">
      <div class="product" *ngFor="let product of cart?.products">
        <ng-container *ngIf="product.quantity > 0">
          <p>{{ product.quantity }}x</p>
          <p>{{ product.name }} {{ !!product.nameVariation ? ' - ' + product.nameVariation : '' }}</p>
          <div class="amount">
            <span class="currency">R$</span>
            <p class="money" [innerHTML]="product.price * product.quantity | spanMoney"></p>
          </div>
        </ng-container>
      </div>
      <div class="total">
        <p>TOTAL</p>
        <div class="amount">
          <span class="currency">R$</span>
          <p class="money" [innerHTML]="cart?.subtotal | spanMoney"></p>
        </div>
      </div>

      <button class="default-button" (click)="generatePixCharge()">confirma</button>
    </div>
  </div>
</div>
<div class="confirmed" *ngIf="transferDone && !transferError">
  <div class="container">
    <div class="title">
      <img src="assets/menu.svg" />
      <h2>Pedido realizado com sucesso! <span class="font-normal">🍽️🍺</span></h2>
    </div>
  </div>
</div>
<div class="not-send" *ngIf="transferDone && transferError">
  <div class="container">
    <div class="title">
      <img src="assets/transaction-error.svg" />
      <h2>Pedido não realizado! <span class="font-normal">😰</span></h2>
      <p>{{ transferMessage }}</p>
    </div>
  </div>
</div>
