<div class="cl-root-container">
  <ng-container *ngIf="finishedLoadingAndUpdating">
    <header></header>

    <div class="cl-content">
      <router-outlet></router-outlet>
    </div>

    <app-footer></app-footer>
  </ng-container>

  <div class="updating" *ngIf="!finishedLoadingAndUpdating">
    <p class="logo">
      <img src="/assets/logo-vazado.svg" alt="Beba!" />
    </p>
    <p class="text-center">
      <mat-spinner diameter="30"></mat-spinner>
    </p>
    <p class="text-center">Atualizando...</p>
  </div>

  <ngx-spinner [fullScreen]="true" bdColor="rgba(0,0,0,0.5)" color="#fff" size="default" type="ball-scale-multiple">
    <p style="color: white">Carregando...</p>
  </ngx-spinner>
</div>
