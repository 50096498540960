import { Injectable } from '@angular/core';

export interface GoogleTagManagerConfig {
  id: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  config: GoogleTagManagerConfig | null;

  private isLoaded = false;

  private browserGlobals: any;

  constructor() {
    this.browserGlobals = {
      windowRef: () => window,
      documentRef: () => document,
    };

    this.setConfigs('GTM-5W542L8');
  }

  getDataLayer() {
    const window = this.browserGlobals.windowRef();
    window.dataLayer = window.dataLayer || [];
    return window.dataLayer;
  }

  pushOnDataLayer(obj) {
    const dataLayer = this.getDataLayer();
    dataLayer.push(obj);
  }

  async addToDom() {
    return new Promise((resolve, reject) => {
      if (this.isLoaded) return resolve(this.isLoaded);

      if (!!this.config?.id) {
        const doc = this.browserGlobals.documentRef();
        this.pushOnDataLayer({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js',
        });
        const gtmScript = doc.createElement('script');
        gtmScript.id = 'GTMscript';
        gtmScript.async = true;
        gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${this.config?.id}`;
        gtmScript.addEventListener('load', () => resolve((this.isLoaded = true)));
        gtmScript.addEventListener('error', () => reject(false));
        doc.head.insertBefore(gtmScript, doc.head.firstChild);
      } else {
        console.log('Google Tag Manager ID is null');
      }
    });
  }

  pushTag(item) {
    return new Promise<void>((resolve, reject) => {
      if (!this.isLoaded) {
        this.addToDom()
          .then(() => {
            this.pushOnDataLayer(item);
            return resolve();
          })
          .catch(() => reject());
      } else {
        this.pushOnDataLayer(item);
        return resolve();
      }
    });
  }

  private setConfigs(googleTagManagerId: string) {
    this.config = Object.assign(Object.assign({}, this.config), {
      id: googleTagManagerId,
    });

    this.addToDom();
  }
}
