<div class="container">
  <h1 class="cl-title text-center">Meu Perfil</h1>

  <p>Abaixo você encontra os seus dados de cadastro:</p>

  <table>
    <tr>
      <td>Nome</td>
      <td>{{ currentUser.name }}</td>
    </tr>
    <tr>
      <td>CPF</td>
      <td>{{ currentUser.cpf | cpf }}</td>
    </tr>
    <tr>
      <td>E-mail</td>
      <td>{{ currentUser.email }}</td>
    </tr>
    <tr>
      <td>Telefone</td>
      <td>{{ currentUser.phone | phoneNumber }}</td>
    </tr>
  </table>

  <p>Para editar alguma informação, entre em contato conosco.</p>
  <hr class="cl-hr" />
  <p class="text-end d-flex flex-column">
    <button (click)="logout()" class="cl-button-primary mb-4">Deslogar</button>
    <a routerLink="/delete-account" class="cl-button-danger">Excluir Conta</a>
  </p>
</div>
