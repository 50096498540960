<div class="container">
  <h1 class="cl-title text-center">Dúvidas</h1>

  <h2 class="cl-title">É seguro usar o cartão de crédito?</h2>
  <p>
    Sim. A Beba! utiliza o PicPay como processadora de cartão de crédito.
  </p>
  <p>
    Nós não recebemos nenhuma informação do seu cartão, o PicPay que cuida de
    toda a segurança de salvar os seus dados e processar o pagamento.
  </p>

  <h2 class="cl-title">É seguro usar o Pix?</h2>
  <p>
    Sim. A Beba! utiliza a Pagar.me como processadora de Pix, empresa do grupo Stone.
  </p>

  <h2 class="cl-title">Como restituir o saldo não utilizado?</h2>
  <p>
    Envie uma mensagem via WhatsApp para o número
    <a href="https://wa.me/+5561993042324" target="_blank">(61) 99304-2324</a> e siga as instruções.
  </p>

  <h2 class="cl-title">Posso usar meu saldo restante em outras edições do evento?</h2>
  <p>
    Sim! Você não precisa pedir o resgate do seu saldo após cada edição, basta usá-lo futuramente em qualquer evento
    Beba! (independente da localização).
  </p>

  <h2 class="cl-title">O meu saldo expira depois de algum tempo?</h2>
  <p>Não. Seu saldo fica disponível independente do tempo que ficar parado.</p>

  <h2 class="cl-title">Ainda tenho dúvidas. Como posso pedir ajuda?</h2>
  <p>Em todas as edições da Feira teremos um balcão de informações; nosso atendente lhe ajudará no que for preciso.</p>

  <h2 class="cl-title">Desenvolvido por</h2>
  <p class="logo">
    <a href="https://www.sys10.com.br" target="_blank">
      <img src="assets/logo-sys10.png" alt="SYS10" />
    </a>
  </p>

  <p class="version text-center">
    {{ version }}<br />
    {{ gitCommitHash }}
  </p>
</div>
