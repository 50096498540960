<div class="error" *ngIf="data?.type === 'error'">
  <div class="container">
    <div class="title">
      <ng-container *ngIf="!data.icon"><img src="assets/transaction-error.svg" /></ng-container>
      <ng-container *ngIf="data.icon"><img src="{{ data.icon }}" /></ng-container>
      <p>{{ data.message }}</p>
    </div>
  </div>
</div>
<div class="success" *ngIf="data?.type !== 'error' && data?.type !== 'confirm'">
  <div class="container">
    <div class="title">
      <ng-container *ngIf="!data.icon"><img src="assets/check.svg" /></ng-container>
      <ng-container *ngIf="data.icon"><img src="{{ data.icon }}" /></ng-container>
      <p>{{ data.message }}</p>
    </div>
  </div>
</div>

<div class="confirm" *ngIf="data?.type === 'confirm'">
  <div class="container">
    <div class="title">
      <p class="confirm">{{ data.message }}</p>
    </div>

    <div class="actions">
      <button (click)="close()" class="cancel">Cancelar</button>
      <button (click)="confirm()">Confirmar</button>
    </div>
  </div>
</div>
