import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EventsService } from '@app/routes/events/events.service';
import { EventDto, EventUserDto, EventUserProductDto, EverUserCartDto } from '@app/routes/events/events.dto';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SellerOrderCheckoutComponent } from './checkout/checkout.component';
import { LoadingService } from '@src/app/services/loading.service';
import { TransferSellerComponent } from '../../transfer/seller/transfer-seller.component';
import Fuse from 'fuse.js';
import { LoginService } from '../../login/login.service';
import { GoogleTagManagerService } from '@app/services/google-tag-manager.service';

@Component({
  selector: 'app-seller-create-order',
  templateUrl: './create-order.component.html',
  styleUrls: ['./create-order.component.scss'],
})
export class SellerCreateOrderComponent implements OnInit, OnDestroy {
  subscriptionEvent: Subscription;

  currentEvent: EventDto;

  subscriptionEventUser: Subscription;

  currentEventUser: EventUserDto | null = null;

  menuList: EventUserProductDto[] | null = [];

  subscriptionCart: Subscription;

  cart: EverUserCartDto | null = { products: [], subtotal: 0 };

  formGroup = new FormGroup({
    amount: new FormControl('', Validators.required),
  });

  subscriptionLoading: Subscription;

  loading = true;

  searching: EventUserProductDto[] | null = [];

  isSearching = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private eventService: EventsService,
    private dialog: MatDialog,
    private loadingService: LoadingService,
    private gtmService: GoogleTagManagerService,
    private loginService: LoginService
  ) {}

  ngOnInit() {
    this.subscriptionLoading = this.loadingService.loading.subscribe((value) => {
      this.loading = value;
    });

    this.subscriptionEvent = this.eventService.currentEvent.subscribe((value) => {
      this.currentEvent = value;
    });

    this.subscriptionEventUser = this.loginService.currentUser.subscribe((value) => {
      this.currentEventUser = value;
    });

    this.subscriptionCart = this.eventService.shopCart.subscribe((shop) => {
      this.cart = shop;
    });

    this.eventService.getUserProducts(this.currentEvent.id, this.currentEventUser?.id).then((result) => {
      const products = result.data;
      const subProducts = products.filter((p) => p.groupId && p.active);

      this.cart = { products, subtotal: 0 };

      products.map((gp) => {
        if (gp.groupId) {
          gp.subProducts = subProducts.filter((p) => p.groupId === gp.groupId).sort((a, b) => a.order - b.order);
        }
      });

      const filteredProducts = products.filter(
        (value, index, self) => index === self.findIndex((t) => t.groupId === value.groupId && t.name === value.name)
      );

      filteredProducts.map((f) => {
        if (f.subProducts?.length) {
          f.subProducts.map((s) => {
            if (s.active && !f.active) {
              f.active = true;
            }
          });
        }
      });

      this.menuList = filteredProducts.sort((a, b) => a.order - b.order);
    });

    const gtmTag = {
      event: 'shop',
      shopEventName: this.currentEvent.name,
      shopProducts: this.menuList,
    };

    this.gtmService.pushTag(gtmTag);
  }

  ngOnDestroy() {
    this.subscriptionEvent.unsubscribe();
    this.subscriptionEventUser.unsubscribe();
    this.subscriptionCart.unsubscribe();
    this.subscriptionLoading.unsubscribe();
  }

  cartAction(product: EventUserProductDto, action, event) {
    if (!action) return;
    event.stopPropagation();

    const pdt = this.cart?.products.find((p) => p.id === product.id);

    if (pdt) {
      switch (action) {
        case 'add':
          pdt.quantity = pdt.quantity ? pdt.quantity + 1 : 1;
          this.cart.subtotal = Number(this.cart.subtotal) + Number(pdt.price);
          break;
        case 'remove':
          if (pdt.quantity > 0) {
            pdt.quantity = pdt.quantity ? pdt.quantity - 1 : 0;
            this.cart.subtotal = Number(this.cart.subtotal) - Number(pdt.price);
          }
          break;
      }
    }

    this.eventService.shopCart.next(this.cart);
  }

  cartProductQuantity(product: EventUserProductDto) {
    const pdt = this.cart?.products.find((p) => p.id === product.id);
    if (!pdt?.quantity) {
      return 0;
    }
    return pdt.quantity;
  }

  checkoutDialog(): void {
    this.dialog.open(SellerOrderCheckoutComponent, {
      panelClass: ['dialog-primary', 'dialog-page-mobile'],
      width: '95%',
      maxWidth: 328,
      height: 'auto',
      maxHeight: '90vh',
      autoFocus: false,
      disableClose: false,
    });
  }

  transferDialog() {
    this.dialog.open(TransferSellerComponent, {
      panelClass: ['dialog-primary', 'dialog-page-mobile'],
      width: '95%',
      maxWidth: 328,
      height: 'auto',
      maxHeight: '90vh',
      autoFocus: false,
      disableClose: false,
      data: {
        amount: this.formGroup.get('amount').value,
      },
    });
  }

  goHome(): void {
    this.router.navigateByUrl('/');
  }

  search(event: any): void {
    const val = event.target.value;

    const fuse = new Fuse(this.menuList, { keys: ['name', 'description'] });
    this.isSearching = val.length > 0;

    if (this.isSearching) {
      this.searching = fuse.search(val).map((p) => p.item);
      return;
    }

    this.searching = [];
  }
}
