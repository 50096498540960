import { FooterService } from './footer.service';
import { CashierService } from './../../routes/cashier/cashier.service';
import { EventsService } from '@app/routes/events/events.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoginService } from '@app/routes/login/login.service';
import { CurrentUserDto } from '@app/routes/login/login.dto';
import { Subscription } from 'rxjs';
import { NotificationsService } from '@src/app/routes/notifications/notifications.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  currentUser: CurrentUserDto | null = null;

  subscriptionCurrentUser: Subscription;

  subscriptionCashier: Subscription;

  subscriptionFooter: Subscription;

  showMenu = false;

  eventId: string = null;

  showTransactions = false;

  subscriptionNotification: Subscription;

  notificationCount = 0;

  constructor(
    private loginService: LoginService,
    private eventService: EventsService,
    public cashierService: CashierService,
    private footerService: FooterService,
    private notificationService: NotificationsService
  ) {}

  ngOnInit() {
    this.eventService.currentEvent.subscribe((value) => {
      if (value) {
        this.eventId = value.id;
      }
    });
    this.subscriptionCurrentUser = this.loginService.currentUser.subscribe((value) => {
      this.currentUser = value;

      if (this.currentUser?.userRole) {
        this.showTransactions = this.currentUser?.userRole.find((r) => r.role === 'store') ? true : false;
      }
    });

    this.subscriptionFooter = this.footerService.isCashier.subscribe((value) => {
      this.showMenu = value;
    });

    this.subscriptionNotification = this.notificationService.unreadNotifications.subscribe(
      (count) => (this.notificationCount = count)
    );
  }

  ngOnDestroy() {
    this.subscriptionCurrentUser.unsubscribe();
    this.subscriptionCashier.unsubscribe();
    this.subscriptionFooter.unsubscribe();
    this.subscriptionNotification.unsubscribe();
  }

  isLoggedIn() {
    return this.currentUser != null;
  }
}
