import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CurrentUserDto } from '../../login/login.dto';
import { LoginService } from '../../login/login.service';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss'],
})
export class DeleteAccountComponent implements OnInit, OnDestroy {
  currentUser: CurrentUserDto;

  subscriptionCurrentUser: Subscription;

  constructor(private loginService: LoginService, private dialog: MatDialog) {}

  ngOnInit() {
    this.subscriptionCurrentUser = this.loginService.currentUser.subscribe((value) => (this.currentUser = value));
  }

  ngOnDestroy() {
    this.subscriptionCurrentUser.unsubscribe();
  }
}
